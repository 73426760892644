<template>
  <div class="frases-container">
    <div v-if="!addressStateRef" class="frases">
      <div class="connect-wallet-alert-div">
        <el-alert
          class="connect-wallet-alert"
          :closable="false"
          effect="dark"
          type="warning"
          show-icon
        >
          <h2>Please connect your wallet to use Frases</h2>
        </el-alert>
      </div>
    </div>

    <div v-if="addressStateRef">
      <FrasesLayout />
    </div>
  </div>
</template>
<script>
  import { useStore } from 'vuex'
  import { computed, onBeforeMount, watch, ref } from 'vue'
  import { selectProjectContract } from '@/services/ProjectService'
  import FrasesLayout from '@/components/projects/frases/frases-layout/'
  export default {
    name: 'Frases',
    components: { FrasesLayout },
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user },
      } = store
      const addressState = computed(() => user.walletAddress)
      const web3 = computed(() => contractState.web3)

      const addressStateRef = ref(addressState)

      onBeforeMount(async () => {
        if (web3.value) {
          await selectProjectContract('frases', web3.value, dispatch)
        }
      })
      watch([web3], async () => {
        await selectProjectContract('frases', web3.value, dispatch)
      })
      return { addressStateRef }
    },
  }
</script>
<style scoped>
  .frases {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
