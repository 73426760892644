<template>
  <el-row v-loading="loading">
    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="frases-col">
      <div style="width: 100%">
        <div class="frases-artwork-img">
          <el-card
            style="width: 100% !important"
            :body-style="{ padding: '10px' }"
          >
            <el-image
              :src="fraseData.image"
              :preview-src-list="srcList"
              :initial-index="0"
              fit="contain"
              style="width: 100%; aspect-ratio: 5/2"
            />
          </el-card>
        </div>
        <p class="frases-artwork-title" style="margin-top: 15px">
          <strong>
            {{ fraseData.phrase.toUpperCase() }}
            by
          </strong>
          <a
            :href="`/artists/${fraseData.artistSlug}`"
            target="_blank"
            class="frases-link"
          >
            {{ fraseData.artist }}
          </a>
          {{ mintedCountsMessage }}
        </p>
        <p class="frase-description" v-if="!readActivated">
          {{ fraseData.description.slice(0, 100) }}...<span
            class="read-more-span"
            @click="toggleReadMore(readActivated)"
            >Read more</span
          >
        </p>
        <p class="frase-description" v-if="readActivated">
          {{ fraseData.description
          }}<span class="read-more-span" @click="toggleReadMore(readActivated)"
            >Read less</span
          >
        </p>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="frases-col">
      <div
        style="margin: 0 0 20px 0"
        v-for="(palabra, index) in fraseData.palabras"
        :key="index"
      >
        <p class="frases-artwork-title">
          <strong>{{ palabra.toUpperCase() }} - </strong>
          {{ availableMessage(palabra.toUpperCase()) }}
        </p>
        <el-select
          v-if="palabraAvailableWalletTokens(palabra.toUpperCase()).length > 0"
          v-model="tokensSelected[index].value"
          @change="selectedToken()"
          placeholder="Select a token"
          :disabled="contractPaused"
        >
          <el-option
            v-for="token in palabraAvailableWalletTokens(palabra.toUpperCase())"
            :key="token.tokenID"
            :label="`${token.word} (ID #${token.tokenID})`"
            :value="token.tokenID"
          />
        </el-select>
      </div>
    </el-col>
    <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8" class="frases-col">
      <div style="width: 100%">
        <el-button
          @click="doMinting()"
          :disabled="contractPaused"
          type="primary"
          class="frases-mint-btn"
          size="large"
        >
          {{ mintButtonMessage() }}
        </el-button>
        <div
          :class="{
            shake: shakeMessage,
            'success-tip': hasAllTokens,
            'info-tip': !hasAllTokens,
          }"
          class="custom-block"
        >
          <span v-html="mintMessage()"></span>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
  import { ref, onBeforeMount, computed } from 'vue'
  import { useStore } from 'vuex'
  import {
    getFraseSignature,
    getFrasesTokenByArtworkID,
  } from '@/services/FrasesService'
  import FrasesContractService from '@/services/FrasesContractService'
  import { selectNotification } from '@/utils/utils'

  export default {
    name: 'FraseMintRow',
    props: ['fraseData', 'contractPaused', 'walletTotalTokens'],
    setup(props) {
      const store = useStore()
      const {
        state: { root, user, contractState },
      } = store

      const walletAddress = computed(() => user.walletAddress)
      const contract = computed(() => contractState.contract)
      const web3 = computed(() => contractState.web3)
      const selectedProject = computed(() => root.selectedProject)

      const loading = ref(false)
      const srcList = [props.fraseData.image]
      const selectedTokenObj = ref({})

      const tokensSelected = ref([])
      const walletAvailableTokens = ref([])
      let frasesContractService

      const mintedCountsMessage = ref('')
      const shakeMessage = ref(false)
      const hasAllTokens = ref(false)

      const readActivated = ref(false)

      const loadContractData = async () => {
        frasesContractService = new FrasesContractService(
          web3.value,
          contract.value
        )
      }

      onBeforeMount(async () => {
        loading.value = true
        for (let i = 0; i < props.fraseData.palabras.length; i++) {
          tokensSelected.value.push({
            word: props.fraseData.palabras[i].toUpperCase(),
            value: null,
          })
        }
        await loadContractData()
        await loadWalletAvailableTokens()
        mintedCountsMessage.value = await getMintedCountsMessage()
        loading.value = false
      })

      const loadWalletAvailableTokens = async () => {
        for (const token of props.walletTotalTokens) {
          const claimed = await frasesContractService.claimedPalabrasTokens(
            token.tokenID
          )
          if (!claimed) {
            walletAvailableTokens.value.push(token)
          }
        }
      }

      const palabraAvailableWalletTokens = (palabra) => {
        return walletAvailableTokens.value.filter(
          (token) => token.word == palabra
        )
      }

      const palabraTotalWalletTokens = (palabra) => {
        return props.walletTotalTokens.filter((token) => token.word == palabra)
      }

      const selectedToken = () => {
        const mappedValues = tokensSelected.value.map((t) => {
          return t.value
        })
        selectedTokenObj.value = {
          artworkID: props.fraseData.artworkID,
          tokenIds: mappedValues,
        }
      }

      const getMintedCountsMessage = async () => {
        const frasesTokens = await getFrasesTokenByArtworkID(
          props.fraseData.artworkID
        ).then((res) => {
          return res.response
        })
        const mintedFraseCounts = frasesTokens.length
        if (mintedFraseCounts > 0) {
          return ` - Minted ${mintedFraseCounts} ${
            mintedFraseCounts == 1 ? 'time' : 'times'
          }`
        } else {
          return ''
        }
      }

      const mintMessage = () => {
        hasAllTokens.value = props.fraseData.palabras.every((palabra) => {
          return palabraAvailableWalletTokens(palabra.toUpperCase()).length > 0
        })

        if (hasAllTokens.value) {
          return "Select which Palabras tokens you'd like to use to mint this artwork."
        } else {
          return 'Collect the corresponding <a href="/palabras" target="_blank" style="text-decoration:none; color:blue">Palabras</a> to mint this artwork.'
        }
      }

      const availableMessage = (palabra) => {
        const totalPalabraTokens = palabraTotalWalletTokens(palabra).length
        const availableTokens = palabraAvailableWalletTokens(palabra).length
        if (totalPalabraTokens > 0) {
          return `${availableTokens} of ${totalPalabraTokens} ${
            totalPalabraTokens == 1 ? 'token' : 'tokens'
          } available`
        } else {
          return 'No eligible tokens in wallet'
        }
      }

      const mintButtonMessage = () => {
        if (props.fraseData.price > 0) {
          return `Mint (${props.fraseData.price} eth)`
        } else {
          return 'Mint (free)'
        }
      }

      const getSignature = async (artworkID, tokenIds, walletAddress) => {
        let response
        let error
        try {
          response = await getFraseSignature(
            artworkID,
            tokenIds,
            walletAddress
          ).then((res) => res.data.response)
        } catch (e) {
          error = e
          console.log('Error getting frase signature', e)
        }
        return { response, error }
      }

      const toggleReadMore = (value) => {
        readActivated.value = !value
      }

      const doMinting = async () => {
        const allowMint = selectedTokenObj.value.tokenIds
          ? selectedTokenObj.value.tokenIds.includes(null)
            ? false
            : true
          : false

        if (allowMint && !props.contractPaused) {
          loading.value = true
          const signatureResponse = await getSignature(
            selectedTokenObj.value.artworkID,
            selectedTokenObj.value.tokenIds.toString(),
            walletAddress.value
          )
          const fraseSignature = signatureResponse.response
          if (fraseSignature) {
            try {
              const { signedFrase, price } = fraseSignature
              if (signedFrase) {
                const params = {
                  contractAddress:
                    selectedProject.value?.configuration.contractAddress,
                  walletAddress: walletAddress.value,
                  palabrasTokenIds: selectedTokenObj.value.tokenIds,
                  artworkId: selectedTokenObj.value.artworkID,
                  signature: signedFrase.signature,
                  mintPrice: price,
                }
                await frasesContractService.publicMint(params)
                selectNotification(null, true)
                window.location.reload()
              } else {
                selectNotification({
                  message: 'The frase could not be signed, please try again',
                })
              }
            } catch (e) {
              console.log('error', e)
              selectNotification(e)
            }
          } else {
            selectNotification({
              message: 'The frase could not be signed, please try again',
            })
          }
          loading.value = false
        } else {
          shakeMessage.value = true
          setTimeout(() => {
            shakeMessage.value = false
          }, 1500)
        }
      }

      return {
        loading,
        srcList,
        tokensSelected,
        mintedCountsMessage,
        shakeMessage,
        hasAllTokens,
        readActivated,
        toggleReadMore,
        mintButtonMessage,
        palabraAvailableWalletTokens,
        palabraTotalWalletTokens,
        selectedToken,
        mintMessage,
        availableMessage,
        doMinting,
      }
    },
  }
</script>

<style scoped>
  .frases-col {
    margin: 10px 0px;
    text-align: left;
  }
  .frases-artwork-img {
    display: inline-block;
    width: 90%;
    box-sizing: border-box;
  }
  .frases-artwork-title {
    font-size: 16px;
    text-align: left;
    margin: 0 0 5px 0;
    white-space: nowrap;
  }
  .frase-description {
    font-size: 14px;
    text-align: left;
    margin: 2% 0 0 0;
    padding-right: 10%;
  }
  .custom-block {
    padding: 12px 20px;
    border-radius: 4px;
    margin: 20px 0;
    font-size: 16px;
    font-weight: bolder;
  }
  .info-tip {
    background-color: rgb(237, 246, 255);
    border-left: 5px solid var(--el-color-primary);
  }
  .success-tip {
    background-color: rgb(237, 255, 249);
    border-left: 5px solid var(--el-color-success);
  }
  .read-more-span {
    color: blue;
    margin-left: 5px;
    cursor: pointer;
  }
  .frases-link {
    text-decoration: none;
    color: blue;
  }
  .frases-mint-btn:focus {
    background-color: var(--el-color-primary);
  }
  .shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  .grow {
    transition: all 0.2s ease-in-out !important;
  }
  .grow:hover {
    transform: scale(1.03) !important;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
<style>
  .el-radio-button__inner {
    border-left: var(--el-border) !important;
  }
</style>
<style>
  .el-collapse,
  .el-collapse-item__header {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
</style>
