import { db } from '@/firebase'
import { FRASES_COLLECTION, TOKEN_COLLECTION } from '@/constants/collections'
import axios from 'axios'

const frasesRef = db.collection(FRASES_COLLECTION)
const tokensRef = db.collection(TOKEN_COLLECTION)

export async function getAllFrases() {
  try {
    const snapshot = await frasesRef.orderBy('artworkID', 'asc').get()
    const { docs } = snapshot

    return docs.map((doc) => {
      const { id } = doc
      return { id, ...doc.data() }
    })
  } catch (error) {
    console.error('Error getting frases.')
    throw error
  }
}

export async function getFraseSignature(artworkID, tokenIds, walletAddress) {
  const params = {
    artworkID: artworkID,
    tokenIdsStr: JSON.stringify(tokenIds),
    walletAddress: walletAddress,
  }

  const signatureResponse = await axios.get(
    process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + '/api/getFrasesSignature',
    { params }
  )

  return signatureResponse
}

export async function getFrasesTokenByArtworkID(artworkID) {
  let response
  let error
  try {
    const snapshot = await tokensRef.where('projectSlug', '==', 'frases').get()
    const frasesTokens = snapshot.docs.map((doc) => doc.data())
    response = frasesTokens.filter((token) => token.artworkID == artworkID)
  } catch (e) {
    error = e
  }

  return { response, error }
}
