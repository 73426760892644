<template>
  <div class="container">
    <el-row class="title-row">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="title">FRASES</div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
        class="s1-header-btn"
      >
        <el-button-group class="frases-buttons">
          <el-button type="primary" @click="browseGallery()">
            Browse Gallery
            <el-icon class="el-icon--right"><Grid /></el-icon>
          </el-button>
        </el-button-group>
      </el-col>
      <el-col :span="24" style="text-align: left">
        <el-tag
          v-if="isContractPaused"
          style="margin: 1% 0"
          effect="dark"
          size="large"
          round
        >
          Minting will start soon.
        </el-tag>
      </el-col>
    </el-row>
    <div v-loading="loading" v-if="connectedToDesiredNetwork">
      <div
        style="margin: 10px 0px"
        v-for="(frase, index) in state.frasesData"
        :key="index"
      >
        <FrasesMintRow
          :fraseData="frase"
          :contractPaused="isContractPaused"
          :walletTotalTokens="walletTotalTokens"
        />
        <hr class="frases-divider" />
      </div>
    </div>
  </div>
</template>

<script>
  import { Grid } from '@element-plus/icons'
  import { useStore } from 'vuex'
  import { computed, ref, onBeforeMount, watch, reactive } from 'vue'

  import { getAllFrases } from '@/services/FrasesService'
  import { getUserTokensByCollectionAddressFromDB } from '@/services/TokenService'
  import { selectProjectContract } from '@/services/ProjectService'
  import { useRouter } from 'vue-router'
  import FrasesMintRow from '@/components/projects/frases/frase-mint-row/index.vue'
  import FrasesContractService from '@/services/FrasesContractService'
  import { NETWORK_IDS } from '@/constants/walletConstants'

  export default {
    name: 'FrasesLayout',
    components: {
      Grid,
      FrasesMintRow,
    },
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { root, user, contractState },
      } = store

      const router = useRouter()
      const loading = ref(false)
      const contract = computed(() => contractState.contract)
      const web3 = computed(() => contractState.web3)
      const isContractPaused = ref(false)
      const walletTotalTokens = ref([])
      const networkId = computed(() => user.networkId)
      const connectedToDesiredNetwork = ref(false)

      const palabrasContractAddress = computed(
        () =>
          root.projects.find((p) => p.name == 'Palabras').configuration
            .contractAddress
      )

      const walletAddress = computed(() => user.walletAddress)

      const state = reactive({
        frasesData: {},
      })
      let frasesContractService

      const loadFrases = async () => {
        frasesContractService = new FrasesContractService(
          web3.value,
          contract.value
        )
        isContractPaused.value = await frasesContractService.paused()

        state.frasesData = await getAllFrases()
      }

      const loadWalletTokens = async () => {
        walletTotalTokens.value = await getUserTokensByCollectionAddressFromDB(
          palabrasContractAddress.value,
          walletAddress.value,
          'palabras'
        )
      }

      const browseGallery = () => {
        dispatch('resetTokenAttrs')
        dispatch('resetTokensData')
        router.push({ path: '/tokens/frases', query: {} })
      }

      onBeforeMount(async () => {
        if (web3.value) {
          loading.value = true
          await selectProjectContract('frases', web3.value, dispatch)
          await loadWalletTokens()
          await loadFrases()
          connectedToDesiredNetwork.value =
            networkId.value &&
            NETWORK_IDS[process.env.NODE_ENV] == networkId.value
          loading.value = false
        }
      })

      watch([web3, networkId], async () => {
        connectedToDesiredNetwork.value =
          networkId.value &&
          NETWORK_IDS[process.env.NODE_ENV] == networkId.value
        loading.value = true
        await selectProjectContract('frases', web3.value, dispatch)
        await loadWalletTokens()
        await loadFrases()
        loading.value = false
      })

      return {
        loading,
        isContractPaused,
        walletTotalTokens,
        connectedToDesiredNetwork,
        browseGallery,
        state,
      }
    },
  }
</script>

<style scoped>
  .space-top {
    margin-top: 38px;
  }

  .title {
    font-weight: bolder;
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
  }
  .title-row {
    margin: 3% 0% 2% 0%;
  }
  .subtitle {
    font-weight: bolder;
    font-size: 25px;
    text-align: left;
    white-space: nowrap;
    margin-bottom: 15px;
  }
  .frases-col {
    margin: 10px 0px;
    text-align: left;
  }
  .container {
    margin: 2% 5%;
  }
  .frases-description {
    text-align: left;
    font-size: 14px;
    padding: 20px 40px 20px 20px;
    border-radius: 4px;
    border-left: 5px solid var(--el-color-primary);
    background-color: rgb(235 245 254);
    margin-right: 30px;
  }
  .s1-header-btn {
    text-align: right;
    margin: 0%;
  }
  .hide-on-small {
    display: block;
  }
  .frases-buttons {
    padding-right: 1%;
  }
  .frases-divider {
    width: 100%;
    margin: 1% 0% 1% 0%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
  }
  @media screen and (max-width: 767px) {
    .s1-header-btn {
      text-align: right;
      margin: 2% 0%;
    }

    .hide-on-small {
      display: none;
    }
  }
</style>
